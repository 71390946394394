import React, { useState } from 'react';

import { IResourceComponentsProps, useApiUrl } from "@pankod/refine-core";

import {
    Edit,
    Form,
    Input,
    useForm,
    Upload,
    getValueFromEvent,
    UploadProps
} from "@pankod/refine-antd";

import {IMissingImagesPattern} from "interfaces";
import type { UploadFile } from 'antd/es/upload/interface';


export const PatternEdit: React.FC<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps } = useForm<IMissingImagesPattern>();
    const apiUrl = useApiUrl();
    const token = localStorage.getItem("access_token")

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
        setFileList(newFileList);
    const [fileList, setFileList] = useState<UploadFile[]>();

    return (
        <Edit saveButtonProps={{...saveButtonProps}}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label="Brand"
                    name={"brand"}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item
                    label="Pattern"
                    name={"pattern"}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled={true}/>
                </Form.Item>
                {/*https://github.com/ant-design/ant-design/blob/master/components/upload/demo/picture-card.tsx*/}
                <Form.Item label="Image">
                    <Form.Item
                        name={["image"]}
                        valuePropName="file"
                        getValueFromEvent={getValueFromEvent}
                        noStyle
                    >
                        <Upload.Dragger
                            name="file"
                            action={`${apiUrl}/api/Upload/image`}
                            headers={{ Authorization: `Bearer ${token}`}}
                            listType="picture-card"
                            onChange={handleChange}
                            fileList={fileList}
                        >
                            <p className="ant-upload-text">
                                Drag & drop a file in this area
                            </p>
                        </Upload.Dragger>
                    </Form.Item>
                </Form.Item>
            </Form>
        </Edit>
    );
};