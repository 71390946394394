import React from "react";
import { Refine, AuthProvider } from "@pankod/refine-core";
import axios, { AxiosRequestConfig } from "axios";
import {
    notificationProvider,
    Layout,
    ErrorComponent,
    AuthPage,
} from "@pankod/refine-antd";
import { LoginOutlined } from "@ant-design/icons";

import routerProvider from "@pankod/refine-react-router-v6";

import "@pankod/refine-antd/dist/reset.css";

import { TyreList, TyreEdit } from "pages/tyres";
import { RimList, RimEdit } from "pages/rims";
import { DesignList, DesignEdit } from "pages/rimsMissingImages";
import { PatternList, PatternEdit } from "pages/tyresMissingImages";
import { DashboardPage } from "pages/dashboard";
import { dataProvider } from "./rest-data-provider";

/**
 * Sends a token request.
 *
 * @param code Authorization code
 * @return {Promise<AxiosResponse<T> | never>}
 */
const sendTokenRequest = (code : string) => {
    const body = [];
    body.push(`client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}`);
    body.push(`code=${ code }`);
    body.push(`grant_type=authorization_code`);
    body.push(`redirect_uri=${process.env.REACT_APP_AUTH_REDIRECT_URL}`);

    return axios.post(`${process.env.REACT_APP_AUTH_TOKEN_ENDPOINT}`, body.join("&"), getTokenRequestHeaders())
        .then(response => {
            if (response.status !== 200) {
                return Promise.reject(new Error("Invalid status code received in the token response: "
                    + response.status));
            }

            return response.data; //, decodeIdToken(response.data.id_token)];
        }).catch((error) => {
            return Promise.reject(error);
        });
};

const getTokenRequestHeaders = () => {
    return {
        headers: {
            "Accept": "application/json",
            "Access-Control-Allow-Origin": `${process.env.REACT_APP_AUTH_REDIRECT_URL}`,
            "Content-Type": "application/x-www-form-urlencoded"
        }
    };
};

const code = new URL(window.location.href).searchParams.get("code");
if (code) {
    sendTokenRequest(code)
        .then(response => {
            localStorage.setItem("token", response);
            localStorage.setItem("access_token", response.access_token);
            window.location.href = `${process.env.REACT_APP_AUTH_REDIRECT_URL}`;
            return Promise.resolve();
        })
        .catch((error => {
            console.log("TOKEN REQUEST ERROR", error);
            //this.setState({ isLoggedIn: false });
            return Promise.reject();
        }));
}

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
    const token = localStorage.getItem("access_token");
    if (request.headers) {
        request.headers["Authorization"] = `Bearer ${token}`;
    } else {
        request.headers = {
            Authorization: `Bearer ${token}`,
        };
    }

    return request;
});

const App: React.FC = () => {
    if(code){
        return (<div/>);
    }
    const authProvider: AuthProvider = {
        login: async ({ email, providerName }) => {
            if (providerName === "aws") {
                window.location.href =
                    `${process.env.REACT_APP_AUTH_LOGIN_URL}?client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}&response_type=code&scope=email+openid+phone&redirect_uri=${encodeURIComponent(String(process.env.REACT_APP_AUTH_REDIRECT_URL))}`;
                return Promise.resolve(false);
            }

            if (email) {
                localStorage.setItem("email", email);
                return Promise.resolve();
            }

            return Promise.reject();
        },
        register: (params) => {
            if (params.email && params.password) {
                localStorage.setItem("email", params.email);
                return Promise.resolve();
            }
            return Promise.reject();
        },
        updatePassword: (params) => {
            if (params.newPassword) {
                //we can update password here
                return Promise.resolve();
            }
            return Promise.reject();
        },
        forgotPassword: (params) => {
            if (params.email) {
                //we can send email with forgot password link here
                return Promise.resolve();
            }
            return Promise.reject();
        },
        logout: () => {
            localStorage.removeItem("token");
            return Promise.resolve();
        },
        checkError: () => Promise.resolve(),
        checkAuth: async () => {
            // TODO handle refresh?
            if(localStorage.getItem("token")){
                return Promise.resolve();
            }

            return Promise.reject();
        },
        getPermissions: () => Promise.resolve(["admin"]),
        getUserIdentity: () =>
            Promise.resolve({
                id: 1,
                name: "Jane Doe",
                avatar: "https://unsplash.com/photos/IWLOvomUmWU/download?force=true&w=640",
            }),
    };

    return (
        <Refine
            authProvider={authProvider}
            dataProvider={dataProvider(`${process.env.REACT_APP_API}`, axiosInstance)}
            routerProvider={{
                ...routerProvider,
                routes: [
                    {
                        path: "/register",
                        element: (
                            <AuthPage
                                type="register"
                                providers={[
                                    {
                                        name: "aws",
                                        label: "Sign in",
                                        icon: (
                                            <LoginOutlined
                                                style={{
                                                    fontSize: 24,
                                                    lineHeight: 0,
                                                }}
                                            />
                                        ),
                                    }
                                ]}
                            />
                        ),
                    },
                    {
                        path: "/forgot-password",
                        element: <AuthPage type="forgotPassword" />,
                    },
                    {
                        path: "/update-password",
                        element: <AuthPage type="updatePassword" />,
                    },
                ],
            }}
            DashboardPage={DashboardPage}
            resources={[
                {
                    name: "TyreCatalog",
                    list: TyreList,
                    edit: TyreEdit
                },
                {
                    name: "RimCatalog",
                    list: RimList,
                    edit: RimEdit
                },
                {
                    name: "RimMissingImages",
                    list: DesignList,
                    edit: DesignEdit
                },
                {
                    name: "TyreMissingImages",
                    list: PatternList,
                    edit: PatternEdit
                },
            ]}
            notificationProvider={notificationProvider}
            LoginPage={() => (
                <AuthPage
                    formProps={{
                        style: { display: "none" }
                    }}
                    providers={[
                        {
                            name: "aws",
                            label: "Sign in"
                        }
                    ]}
                />
            )}
            Layout={Layout}
            catchAll={<ErrorComponent />}
        />
    );
};

export default App;