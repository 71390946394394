import React from "react";
import { HttpError } from "@pankod/refine-core";
import {
    List,
    Table,
    useTable,
    Space,
    EditButton,
    Form
} from "@pankod/refine-antd";

import {IMissingImagesDesign } from "../../interfaces";

export const DesignList: React.FC = () => {
    const { tableProps, searchFormProps } = useTable<IMissingImagesDesign, HttpError>(
        {
        });

    return (
        <List title="Designs with missing images">
            <Form
                {...searchFormProps}
                layout="vertical"
            >
            </Form>
            <Table
                {...tableProps}
                rowKey="ean"
                pagination={{
                    ...tableProps.pagination,
                }}
            >
                <Table.Column
                    dataIndex={"brand"}
                    title="Brand"
                />
                <Table.Column
                    dataIndex={"design"}
                    title="Design"
                />
                <Table.Column
                    dataIndex={"color"}
                    title="Color"
                />
                <Table.Column<IMissingImagesDesign>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.designId}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};