import React from "react";
import { CrudFilters, HttpError } from "@pankod/refine-core";
import {
    List,
    Table,
    Input,
    useTable,
    Space,
    EditButton,
    Form, ImageField,
} from "@pankod/refine-antd";

import {IRim, IRimFilterVariables} from "../../interfaces";

export const RimList: React.FC = () => {
    const { tableProps, searchFormProps } = useTable<IRim, HttpError, IRimFilterVariables>(
        {
            onSearch: (params) => {
                const filters: CrudFilters = [];
                const { search } = params;

                filters.push(
                    {
                        field: "search",
                        operator: "eq",
                        value: search,
                    }
                );

                return filters;
            },
        });

    return (
        <List title="Rims">
            <Form
                {...searchFormProps}
                layout="vertical"
                onValuesChange={() => searchFormProps.form?.submit()}
            >
                <Space wrap>
                    <Form.Item label="Search" name="search">
                        <Input
                            allowClear
                            placeholder="Search"
                        />
                    </Form.Item>
                </Space>
            </Form>
            <Table
                {...tableProps}
                rowKey="ean"
                pagination={{
                    ...tableProps.pagination,
                }}
            >
                <Table.Column
                    dataIndex={"ean"}
                    title="EAN"
                />
                <Table.Column
                    dataIndex={["brand", "name"]}
                    title="Brand"
                />
                <Table.Column
                    dataIndex={["pattern", "name"]}
                    title="Pattern"
                />
                <Table.Column<IRim>
                    title="Size"
                    dataIndex="size"
                    render={(_, record) => (
                        "foo"
                    )}
                />
                <Table.Column<IRim>
                    title="Image"
                    dataIndex="image"
                    render={(_, record) => (
                        <ImageField
                            value={record.design.images.stored}
                            width={200}
                        />
                    )}
                    width="10%"
                />
                <Table.Column<IRim>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.ean}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};