import React from "react";
import {CrudFilters, HttpError} from "@pankod/refine-core";
import {
    List,
    Table,
    useTable,
    Space,
    EditButton,
    Form, Input
} from "@pankod/refine-antd";

import {IMissingImagesPattern, IMissingImagesFilterVariables } from "../../interfaces";

export const PatternList: React.FC = () => {
    const { tableProps, searchFormProps } = useTable<IMissingImagesPattern, HttpError, IMissingImagesFilterVariables>(
        {
            onSearch: (params) => {
                const filters: CrudFilters = [];
                const { search } = params;

                filters.push(
                    {
                        field: "search",
                        operator: "eq",
                        value: search,
                    }
                );

                return filters;
            },
        });

    return (
        <List title="Designs with missing images">
            <Form
                {...searchFormProps}
                layout="vertical"
                onValuesChange={() => searchFormProps.form?.submit()}
            >
                <Space wrap>
                    <Form.Item label="Search" name="search">
                        <Input
                            allowClear
                            placeholder="Search"
                        />
                    </Form.Item>
                </Space>
            </Form>
            <Table
                {...tableProps}
                rowKey="ean"
                pagination={{
                    ...tableProps.pagination,
                }}
            >
                <Table.Column
                    dataIndex={"brand"}
                    title="Brand"
                />
                <Table.Column
                    dataIndex={"pattern"}
                    title="Pattern"
                />
                <Table.Column<IMissingImagesPattern>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.patternId}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};